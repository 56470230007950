import React, {useContext} from "react";
import {CareerMidLayout, CareerMidNoLayout} from "../../../../organisms/careers/CareerMidLayout";
import {Interview4Content} from "../../../../organisms/interviews/Interview4Content";
import {SuprieveNowHeader} from "../../../../organisms/menus/SuprieveNowHeader";
import {Layout} from "../../../../organisms/Layout";
import {md} from "../../../../atoms/core/media-queries";
import {WindowSizeContext} from "../../../../atoms/core/WindowSizeProvidor";

const Content = () => {
  const {width: windowWidth} = useContext(WindowSizeContext);
  const extraHeaders = windowWidth > md ? <SuprieveNowHeader current="/4" prefix="/careers/mid/suprieve-now"/> : null;
  return <CareerMidNoLayout current="suprieve-now"
                          extraHeaders={extraHeaders}>
    <Interview4Content prefix="/careers/mid/suprieve-now"/>
  </CareerMidNoLayout>;
};
export default () => <Layout><Content/></Layout>;