import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../styles/careers/interviewmid.module.scss";
import quotationStart from "../../images/career_interview/midQuotationStart.png";
import quotationEnd from "../../images/career_interview/midQuotationEnd.png";
import { SuprieveNowHeader } from "../menus/SuprieveNowHeader";
import { InterviewLink1 } from "./InterviewLink1";
import { InterviewLink2 } from "./InterviewLink2";
import { InterviewLink3 } from "./InterviewLink3";
import { InterviewLink5 } from "./InterviewLink5";
import sagaraImg1 from "../../images/career_interview/sagara/wb9a5370.jpg";
import { InterviewLink4 } from "./InterviewLink4";
import sagaraImg2 from "../../images/career_interview/sagara/firstImg.png";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/career_new_top/_kpo0802_newgraduate_head.jpg";
import SagaraLastImg from "../../images/career_interview/sagara/lastImage.png";

export const Interview4Content = ({ prefix }, { location: pathname }) => (
  <>
    <SEO
      pathname={pathname}
      title="インタビュー４ | suprieve-now"
      description="スプリーブホールディングスで働く社員にインタビュー | 「将来の自分にワクワクする会社」（相良 実咲 / Evand / 2018年入社）"
      image={seoImage}
    />

    <div className={styles.all}>
      <div className={styles.nameContainerRight}>
        <StaticImage
          className={styles.nameImg}
          src="../../images/career_interview/sagara/wb9a5373.jpg"
          alt=""
        />
      </div>
      <div className={styles.employee}>
        <h2>Employee Interveiw</h2>
      </div>

      <div className={styles.nameContainer}>
        <div className={styles.nameContainerLeft}>
          <div className={styles.floatName}>
            <h2>相良 実咲</h2>
            <h3>Suprieve Holdings</h3>
            <h4>2018年入社</h4>
            <h5>
              将来の自分に
              <br />
              ワクワクする会社
            </h5>
          </div>
        </div>
      </div>

      <div className={styles.summary}>
        <h2>Career Summary</h2>
        <p>
          2015年～2018年：
          <br />
          新卒でANA大阪空港株式会社へ入社。グランドスタッフ職として勤務。
          <br />
          地上接客業務全般の経験を経て空港オペレーションマネージメント業務を担当。
          <br />
          現場で働く社員のフォローやイレギュラー対応の責任者として勤務。
        </p>
      </div>

      <div className={styles.workContainer}>
        <img className={styles.workImg} src={sagaraImg1} alt="" />
        <div className={styles.workContainerRight}>
          <div className={styles.triangle}> </div>
          <div className={styles.workTitle}>
            <h2>仕事内容</h2>
          </div>
          <p>
            取締役の熱いオファーをいただき、2018年にSuprieve Holdings株式会社
            人材事業部(現Evand(株))新卒採用課として入社し、大阪本社で勤務。主にに説明会や面接を1年6か月間担当。
            <br></br>
            東京本社新卒採用責任者に就任し、東京本社で勤務。主に面接、新入社員研修、採用管理を1年間担当。
            <br />
            現在は全国新卒採用責任者に就任。東京本社新卒採用責任者兼任。
            <br />
            最終面接、採用管理、新入社員研修、採用担当育成を担当。
          </p>
        </div>
      </div>

      {/* SP-ONLY */}
      <div className={styles.firstImageSp}>
        <img className={styles.firstImageBodySp} src={sagaraImg2} alt="" />
      </div>

      <div className={styles.backgroundSquareSagara} />

      <div className={styles.interviewContent}>
        <div className={styles.firstContainer}>
          <div className={styles.firstContainerLeft}>
            <h3>Suprieveに入社を決めた理由をお教えください。</h3>
            <p>
              「役員の熱い言葉に胸を打たれたこと」と「事業成長のスピード感」を感じたからです。
              <br />
              友人の付き添いで訪問した会社説明会で、役員の方が最後にお話をしてくださいました。
              <br />
              Suprieveの将来に対する思いや仲間への熱量を会場で体感し、感動して不意に涙が出たことを印象深く覚えています。その後何度か会社の方とお会いしましたが、どんな時もワクワク
              <br />
              新しい事業や取り組みについてお話いただき「私も人生一度の中でこの環境に身を置きたい！」と自身の可能性にドキドキしました。
            </p>
            <h3>所属する人材事業部のご紹介をお願いします。</h3>
            <p>
              “思いやりのプロフェッショナル集団”だと思います。
              <br />
              人に対する思いが強い、人情に熱い仲間が集結していると思います。
              思いやりが強いからこそ、就活生と
              <br />
              「心と心でしっかり向き合う」
              <br />
              「本気で”一緒に”将来を考える」
              そんな、面接以上の繋がりが実現しているのではないかと思います。
              『この上ない最高の会社』を共に創っていく仲間との出会いをワクワクしながら、活発に共有し合っている採用担当たちの光景をよく目にします。
              <br />
              「私たちの出会いから将来のSuprieveが創られる」という思いで
              日々の出会いを大切にしています。
            </p>
          </div>
          <div className={styles.firstContainerRight}>
            <img className={styles.firstImgSagara} src={sagaraImg2} alt="" />
          </div>
        </div>

        <div className={styles.secondContainer}>
          <div className={styles.secondContainerLeft}>
            <h2>
              <img src={quotationStart} />
              私たちの出会いから
              <br />
              将来のSuprieveが創られる
              <img src={quotationEnd} />
            </h2>
          </div>
        </div>

        <div className={styles.thirdContainer}>
          <div className={styles.textSagaraOne}>
            <h3>相良さんからみたSuprieveはどのような会社ですか？</h3>
            <p>
              “将来の自分にワクワクする会社”
              <br />
              <br />
              この言葉が1番しっくりくる会社じゃないかな、と思います。
              <br />
              入社前と入社後、この感情にギャップがありませんでした。むしろ入社後のほうが、ワクワクしています！(笑)
              <br />
              ー役員陣の熱量が半端ない。かっこよすぎる。
              <br />
              ー多角的に事業を拡げているため知らない情報を知れる！
              <br />
              ー会社の情報更新頻度が早い！広報担当が大活躍中！
              <br />
              ー創業から現在まで増収増益を継続している！会社が大きくなっていくことを実感する！
              <br />
              ー裁量権を多く持たせてくれる環境で信頼してくれている事を感じる！
              <br />
              ー年齢、性別、今までのキャリアに関係なく挑戦させてくれる！
              <br />
              挙げ始めたら沢山出てきますね(笑)
              <br />
              こんなに目まぐるしく変化している会社だからこそ、仲間と協力する機会が多いですし困難があっても絆の深い仲間に相談しながら進められるので『自己成長』を感じられます。
            </p>
            <h3>仕事をする上での「働きがい」や「やりがい」は感じますか？</h3>
            <p>
              自分たちが採用した新卒社員がキャリアアップしていくことですね。
              <br />
              新卒社員が学生の時に出会っている私たち採用担当は、一人一人の入社前入社後のビフォーアフターがわかるんです。
              <br />
              ドキドキしながら社会に出た一年目の社員が数々の壁を乗り越えながらも評価されていく姿は最高のやりがいです。
            </p>
            <h3>今後の目標を教えてください</h3>
          </div>
        </div>

        <div className={styles.fourthContainer}>
          <div className={styles.fourthContainerLeft}></div>
          <div className={styles.fourthContainerRight}>
            <h2>
              <img src={quotationStart} />
              <span>『語れるオカン』になることです。</span>
              <img src={quotationEnd} />
            </h2>

            <p>
              <br />
              この将来の夢、よく笑われるのですが女性からの支持は熱いです(笑)
              <br />
              <br />
              将来、私は家庭を持って母親になりたい夢があります。
              <br />
              自分の子供にはのびのびと育ってほしい。やりたい事にどんどん挑戦してほしい。
              <br />
              でも悩んだ時には親友のような関係の母ちゃんに相談してほしいんです。
              <br />
              いつでも子供の背中を押せる、幅広い経験を積んだ母になることが夢です。
              <br />
              だから今、どんなことも挑戦して失敗して立ち上がってを繰り返しています。
              <br />
              尊敬できるたくさんの仲間と共に、それぞれの夢を叶えていきたいと思います。
            </p>
          </div>
        </div>

        <div className={styles.fifthContainer}>
          <div className={styles.fifthContainerLeft}>
            <img className={styles.fifthImg} src={SagaraLastImg} alt="" />
          </div>
          <div className={styles.fifthContainerRight}></div>
        </div>
      </div>

      <div className={styles.otherInterview}>
        <h2>他の先輩のインタビューを見る</h2>

        <div className={styles.interviewContainer}>
          <InterviewLink1 prefix={prefix} />
          <InterviewLink2 prefix={prefix} />
          <InterviewLink3 prefix={prefix} />
          <InterviewLink4 prefix={prefix} />
          <InterviewLink5 prefix={prefix} />
        </div>

        <div className={styles.interviewContainerSp}>
          <InterviewLink1 prefix={prefix} />
          <InterviewLink2 prefix={prefix} />
          <InterviewLink3 prefix={prefix} />
          <InterviewLink4 prefix={prefix} />
          <InterviewLink5 prefix={prefix} />
        </div>
      </div>
    </div>
  </>
);
